<template>
    <div>
        <b-card-code>
            <b-row class="mb-1">
                <b-col>
                    <h2>
                        Selecciona el rango de fechas a consultar
                    </h2>
                </b-col>                
            </b-row>
            
            <b-row>
                <b-col md="3" xl="3" class="mb-1">
                    <label for="example-datepicker">Fecha de Inicio</label>
                    <b-form-datepicker show-decade-nav="true" v-model="startDate" id="example-datepicker2" class="mb-1"/>
                </b-col>
                <b-col md="3" xl="3" class="mb-1">
                    <label for="example-datepicker">Fecha Final</label>
                    <b-form-datepicker show-decade-nav="true" v-model="endDate" id="example-datepicker3" class="mb-1"/>
                </b-col>
                <b-col v-if="!processing" md="3" xl="3" class="mt-2 ">
                    <b-button variant="primary" @click="GetMetrics" block >                
                        <span class="align-middle">Obtener Métricas</span>
                    </b-button> 
                </b-col>
                <b-col v-if="processing" md="3" lg="3" class="mt-2">
                    <b-button variant="primary" disabled block><b-spinner small label="Cargando..." /> Cargando...</b-button>
                </b-col>
            </b-row>
        </b-card-code>

        <b-card-code v-if="this.metricsCard">
            <span><b>Vendido en Kilos</b></span>
            <br>
            <span>Total_Vendido_En_Kilos: {{parseFloat(metrics.total_Vendido_En_Kilos).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}</span>
            <br>
            <span>Total_Vendido_En_Kilos_Nuevos: {{parseFloat(metrics.total_Vendido_En_Kilos_Nuevos).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}</span>
            <br>
            <span>Total_Vendido_En_Kilos_Recurrentes: {{parseFloat(metrics.total_Vendido_En_Kilos_Recurrentes).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}</span>
            <br><br>
            <span><b>Vendido en Pesos</b></span>
            <br>
            <span>Total_Vendido_En_Pesos: {{parseFloat(metrics.total_Vendido_En_Pesos).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}</span>
            <br>
            <span>Total_Vendido_En_Pesos_Nuevos: {{parseFloat(metrics.total_Vendido_En_Pesos_Nuevos).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}</span>
            <br>
            <span>Total_Vendido_En_Pesos_Recurrentes: {{parseFloat(metrics.total_Vendido_En_Pesos_Recurrentes).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}</span>
            <br><br>
            <span><b>Cantidad de Inversiones</b></span>
            <br>
            <span>Cantidad_De_Inversiones_Total: {{metrics.cantidad_De_Inversiones_Total}}</span>
            <br>
            <span>Cantidad_De_Inversiones_Nuevas: {{(metrics.cantidad_De_Inversiones_Nuevas)}}</span>
            <br>
            <span>Cantidad_De_Inversiones_Recurrentes: {{metrics.cantidad_De_Inversiones_Recurrentes}}</span>
            <br><br>
            <span><b>Ticket Promedio</b></span>
            <br>
            <span>Ticket_Promedio_Total: {{parseFloat(metrics.ticket_Promedio_Total).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}</span>            
            <br>
            <span>Ticket_Promedio_Nuevas: {{parseFloat(metrics.ticket_Promedio_Nuevas).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}</span>
            <br>
            <span>Ticket_Promedio_Recurrentes: {{parseFloat(metrics.ticket_Promedio_Recurrentes).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}</span>
            <br><br>
            <span><b>Clientes</b></span>
            <br>
            <span>Cantidad_De_Clientes_Total: {{metrics.cantidad_De_Clientes_Total}}</span>
            <br>
            <span>Cantidad_De_Clientes_Nuevos: {{metrics.cantidad_De_Clientes_Nuevos}}</span>
            <br>
            <span>Cantidad_De_Clientes_Recurrentes: {{metrics.cantidad_De_Clientes_Recurrentes}}</span>
            <br><br>
            <span><b>Usuarios</b></span>
            <br>
            <span>Cantidad_De_Nuevos_Usuarios: {{metrics.cantidad_De_Nuevos_Usuarios}}</span>
            <br>
            <span>Cantidad_De_Usuarios_Total_A_Fecha_De_Cierre: {{metrics.cantidad_De_Usuarios_Total_A_Fecha_De_Cierre}}</span>            
            <br><br>
            <span><b>Dinero</b></span>
            <br>
            <span>Total_Dinero_En_Billeteras: {{parseFloat(metrics.total_Dinero_En_Billeteras).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}</span>
            <br>       
            <span>Total_Solicitudes_Dinero_EnProceso: {{ parseFloat(metrics.total_Solicitudes_Dinero_EnProceso).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") }}</span>
            <br>     
            <span>Total_Liquidaciones_Pendiente_Carga: {{ parseFloat(metrics.total_Liquidaciones_Pendiente_Carga).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") }}</span>
            <br>   
        </b-card-code>

    </div>
</template>


<script>
import axios from '@axios'
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue';
import { BAlert, BTable, BForm, BFormInput, BProgress, BFormGroup, BFormSelect, BSpinner, BButton, BRow, BCol,BFormDatepicker} from 'bootstrap-vue'
import departamentos from '../../assets/departamentos-municipios.json'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BFormDatepicker,
        BCardCode,
        BTable,
        BForm,
        BProgress,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BButton,
        BFormSelect,
        BAlert,
        BSpinner,
    },
    data(){
        return{
            processing: false,
            startDate: null,
            endDate: null,
            metrics: {},
            metricsCard: false,
        }
    },
    created(){ 
        
    },
    watch:{
      startDate: function(val) { this.startDate = val; },
      endDate: function(val) { this.endDate = val; },
    },
    methods:{
        GetMetrics(){
            if(this.endDate >= this.startDate){  
                this.processing = true;              
                axios.get('/Metrics/GetMetrics',  { params: { startDate: this.startDate, endDate: this.endDate}})
                    .then(response => {     
                        this.metricsCard = true;                                                        
                        this.metrics = response.data;
                        this.processing = false;
                    })
            } else {
                this.processing = false;
                this.$bvToast.toast("Revisa el Rango de fechas", { title: 'Error', variant: 'danger', solid: true, });
            }
        },
    },
    directives: {
        Ripple,
    },
}
</script>